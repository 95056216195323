<template>
  <BaseListPage locale-section="pages.uris" route="uri">
    <URISList />
  </BaseListPage>
</template>

<script>
export default {
  name: "URISPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    URISList: () => import("./URISList")
  }
};
</script>
